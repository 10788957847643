import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "CloudFront のエラー監視精度を上げた話",
  "date": "2020-12-23T08:54:28.000Z",
  "slug": "entry/2020/12/23/175428",
  "tags": ["medley"],
  "hero": "./2020_12_23.png",
  "heroAlt": "CloudFront"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`はじめまして、メドレー新卒入社 2 年目の森川です。`}</p>
    <p>{`インフラ経験がまだ 4 ヶ月ほどの未熟者ですが、`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/certification/certified-cloud-practitioner/"
      }}>{`AWS 認定資格クラウドプラクティショナー`}</a>{` の試験に合格することができました。上位の資格取得に向けて今後も勉強していきます。`}</p>
    <p>{`先日私が担当させていただいた CloudFront のアラート改善について、問題の原因と対応方法を本記事で書かせていただきます。`}</p>
    <p>{`よろしければお付き合いください。`}</p>
    <h1>{`背景と問題`}</h1>
    <p>{`弊社が運営しているプロダクトの一つ `}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{` ではインフラ環境に AWS を利用しています。`}</p>
    <p>{`監視には CloudWatch や Datadog などを使用しています。サービスの異常を検知するための設定のひとつに、CloudFront のエラーレスポンス増加を検知するためのアラート通知があります。`}</p>
    <p>{`CloudFront が返すレスポンスのうち、特定の時間範囲の中で 4xx, 5xx 系のエラーを返した割合が閾値を超過したことを検知して、CloudWatch アラームから Lambda を通して Slack に通知を行っています。`}</p>
    <p>{`ところが、ある頃を境に CloudFront での 4xx 系エラーレスポンスの発生割合が増加し、アラートの通知頻度が想定以上に高くなってしまいました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201221/20201221172048.png",
      "alt": "20201221172048.png"
    }}></img>
    <h1>{`原因`}</h1>
    <p>{`調査を行ったところ、刷新した社内システムにて以下 2 つの原因でアラートが発生していることが分かりました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201221/20201221172100.png",
      "alt": "20201221172100.png"
    }}></img>
    <h2>{`原因 1. 社外サービスからのアクセスでアラートが発生`}</h2>
    <p>{`CloudFront のログを確認したところ、社外サービス（Slack, Google スプレッドシートなど）からのアクセスに対してステータスコード 403 を返しているレスポンスログが数多く記録されていました。`}</p>
    <p>{`これらのサービスに弊社の社内管理システムの URL がポストされると、プレビューを表示するためのリクエストが送信されますが、この時のリクエストが社外からのアクセスとして WAF で制限されていました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201221/20201221172124.png",
      "alt": "20201221172124.png"
    }}></img>
    <p>{`インフラ刷新前から現在まで稼働している CloudFront のログも確認したところ、こちらでも同様のエラーレスポンスが発生していることが分かりました。しかし、エラー割合増加のアラートが頻発することは現在でもほとんどありません。`}</p>
    <p>{`以前はジョブメドレーが持つシステム全体へのアクセスをひとつの CloudFront で処理していたため、アラート通知の割合として計算する際の母数が大きく、社外からのアクセスによるエラーが発生していても、その割合が閾値を超過することが少なかったからだと考えられます。`}</p>
    <p>{`インフラ構成を刷新したことをきっかけに、これまで目立っていなかった社外からのアクセスという問題が表面化してきたのです。`}</p>
    <h2>{`原因 2. 利用者が少ない時間にエラーレートが高くなりアラートが発生`}</h2>
    <p>{`CloudWatch アラームでは、一定期間内でのレスポンスのうち、4xx, 5xx 系のエラーごとにその割合が閾値を超過したことを検知してアラートを発生させる設定としていました。`}</p>
    <p>{`しかし、深夜など利用者が少ない時間に一度でもエラーが発生すると、その割合が跳ね上がってしまうことでアラート発生頻度が増加し、誤検知と言える状態になっていました。`}</p>
    <p>{`以下の画像では、4xx 系エラーの割合が夜間に 100%となっている箇所が確認できます。（表示時間は UTC です）`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201221/20201221172140.png",
      "alt": "20201221172140.png"
    }}></img>
    <h1>{`対応方法`}</h1>
    <p>{`2 つの原因に対し、それぞれ対応を行いました。`}</p>
    <h2>{`対応 1. 特定の社外サービスからのアクセスをエラー検知の対象外とする`}</h2>
    <p>{`各サービスの設定により、プレビュー表示によるアクセスを停止させる選択肢が考えられます。しかし、該当するサービスすべてに設定を行うのは難しく、管理も複雑になりそうです。`}</p>
    <p>{`そこで、特定の社外サービスからのアクセスを `}<strong parentName="p">{`エラー検知の対象外とする`}</strong>{` 方針で対応を行いました。`}</p>
    <p>{`ログのすべてを CloudWatch アラームの評価対象としていたために、誤検知と言えるアラートが発生しているのが現状です。したがって、評価させたいログだけに絞り CloudWatch で評価させることができれば解決が図れます。今回であれば、特定のユーザーエージェントや IP アドレスなどを除外して CloudWatch に渡すという処理が求められます。`}</p>
    <p>{`その実現のため、今回新たに作成したのが Lambda の関数です。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201221/20201221172156.png",
      "alt": "20201221172156.png"
    }}></img>
    <p>{`S3 に CloudFront のログが保存されたことをトリガーに Lambda を起動させるように設定しました。`}</p>
    <p>{`ログごとに記録されているリクエスト元のユーザーエージェントや IP アドレスなどを確認し、除外対象かどうかを判定します。`}</p>
    <p>{`そうして選別を通過したログを今度はステータスコードの 5 つのクラス（1xx, 2xx, 3xx, 4xx, 5xx 系）ごとに振り分けます。`}</p>
    <p>{`ただし、CloudFront ではステータスコードに `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`000`}</code>{` が入ることがあります。`}</p>
    <undefined><img {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201221/20201221172213.png",
        "alt": "20201221172213.png"
      }}></img>{`
https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/AccessLogs.html`}</undefined>
    <p>{`ステータスコード `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`000`}</code>{` はアラートで検知したところで対応できることが特にないため、検知対象から除外する方針としました。`}</p>
    <p>{`（S3 のログを直接確認すると `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`000`}</code>{` なのですが、Athena でログを確認すると `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{` で表示されるため、少しハマりました）`}</p>
    <p>{`こういった意図しない値がステータスコードに含まれていた場合などを検知できるようにするため、5 つのクラス以外の値が含まれていた場合に `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`UNKNOWN_STATUS_CODE`}</code>{` なクラスとして分類するようにしました。`}</p>
    <p>{`必要なものに絞ったログを 6 つのステータスパターンに分け、それぞれの件数を CloudWatch メトリクスへ PUT させます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201221/20201221172229.png",
      "alt": "20201221172229.png"
    }}></img>
    <p>{`ここまでが Lambda の仕事となります。`}</p>
    <p>{`各ステータスのログの件数を CloudWatch メトリクスで確認できるようになったので、レスポンス全体における 4xx, 5xx 系エラーの割合が算出できます。これを元に閾値を設定し、以前のようなアラートを作成することができました。`}</p>
    <h2>{`対応 2. CloudWatch アラームの検知ルールを調整する`}</h2>
    <p>{`利用者が少ない時間にエラーレートが高くなりアラートが発生する件については、
`}<strong parentName="p">{`CloudWatch アラームの検知ルールを調整`}</strong>{` することによって対応しました。`}</p>
    <p>{`一定期間でのエラー数に閾値を定め、超過した際にアラートを通知するように変更しました。つまり、割合ではなく絶対数で判断させるようにしています。`}</p>
    <p>{`以下の画像の緑色のグラフが新たな検知ルールで参照するものとなります。橙色で示しているのが 4xx 系エラーの割合ですが、これが 100%となっている箇所においても新たな検知ルールには反応していないことが分かります。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20201221/20201221172242.png",
      "alt": "20201221172242.png"
    }}></img>
    <h1>{`対応を終えて`}</h1>
    <p>{`Lambda を用いた集計処理の作成と、アラートの検知ルールの調整を行うことで、CloudFront のエラー監視精度を向上させることができました。`}</p>
    <p>{`以前は頻繁にアラートがあがっていましたが、対応後はすっかり落ち着きを見せています。`}</p>
    <p>{`システムの安定稼働を実現するためにも、適切にアラートを検知できるように今後も改善を図っていきたいと思います。`}</p>
    <p>{`今回の課題に対する解決手段としてはシンプルな対応であったかとは思いますが、私には実りの多い紆余曲折な経験となりました。`}</p>
    <p>{`AWS の基本的なサービスの連携を学ぶことができたことに加え、新たに作成する AWS のサービスの課金額の試算や、実行計画を定めてからの実装など事前準備を意識して取り組むことができました。恵まれた環境の中、日々学ばせていただいております。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`メドレーでは「医療ヘルスケアの未来をつくる」というミッションを掲げ、各プロダクトの開発・運営が進められています。`}</p>
    <p>{`エンジニア・デザイナーをはじめ多くのポジションで新たなメンバーを募集しています。ご興味をお持ちいただけた方は、ぜひお気軽にお話しさせていただければと思います！`}</p>
    <p>{`ここまでお付き合いいただき、ありがとうございました。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      